<template>
  <div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8 border rounded bg-white p-8">
      <div>
        <div class="mx-auto text-center text-2xl font-bold">
          <img src="@/assets/logo.svg" :alt="appName" />
        </div>

        <h3
          v-if="loginError"
          :class="`text-center text-xs my-4 text-${errorClass}-500`"
          v-html="error"
        ></h3>
      </div>
      <form class="mt-8 space-y-6" action="#" method="POST">
        <input type="hidden" name="remember" value="true" />
        <div class="rounded space-y-4">
          <div>
            <label for="email-address" class="sr-only">E-Mail-Adresse</label>
            <input
              id="E-Mail Adresse"
              name="email"
              type="email"
              autocomplete="email"
              v-model="email"
              required
              class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none sm:text-sm"
              placeholder="E-Mail addresse"
            />
          </div>
          <div>
            <label for="password" class="sr-only">Passwort</label>
            <input
              id="password"
              name="password"
              type="password"
              autocomplete="current-password"
              v-model="password"
              required
              class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none sm:text-sm"
              placeholder="Passwort"
            />
          </div>
        </div>

        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <input
              id="remember_me"
              name="remember_me"
              type="checkbox"
              class="h-4 w-4 border-gray-300 rounded"
              v-model="keepMeLogin"
            />
            <label for="remember_me" class="ml-2 block text-sm text-gray-900">
              Eingeloggt bleiben
            </label>
          </div>

          <div class="text-sm">
            <router-link to="forgot" class="font-medium text-indigo-600 hover:text-indigo-500">
              Passwort vergessen?
            </router-link>
          </div>
        </div>

        <div>
          <button
            type="submit"
            @click.prevent="login"
            class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <!-- Heroicon name: solid/lock-closed -->
              <svg
                class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
            Anmelden
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { clearAuthTokens } from '@/services/auth'

export default {
  name: 'logout',
  components: {},
  data() {
    return {}
  },
  mounted() {
    this.logout()
  },
  methods: {
    logout() {
      clearAuthTokens()

      this.$router.push('/login')
    },
  },
}
</script>

<style></style>
